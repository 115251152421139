import Link from 'next/link';
import { FC, useContext } from 'react';
import StyledIcon from '@/components/base/StyledIcon';
import WhatsOnNowContext, { WhatsOnNowProvider } from '@/contexts/WhatsOnNowContext';
import LiveLogo from '@/public/puma/images/icons/Live.svg';
import PBSKidsLogo from '@/public/puma/images/logos/PBSKidsLogo.svg';
import { Maybe, PbsKidsMultiPlatformSeries } from '@/types/pbskids-graph';
import styles from './LiveModule.module.scss';
import CharacterCard from '@/components/base/CharacterCard';

interface Props {
  targetProperty?: Maybe<PbsKidsMultiPlatformSeries>
  dangerouslyForceSeries?: PbsKidsMultiPlatformSeries// Force override the Series. For DESIGN REVIEW ONLY.
}

const LiveModuleLink: FC<Props> = ({ targetProperty, dangerouslyForceSeries }) => {
  let { episode, series } = useContext(WhatsOnNowContext);

  if (dangerouslyForceSeries) {
    series = dangerouslyForceSeries;
  }

  const seriesTitle = series?.title || '';

  /**
   * If targetProperty is NOT defined then this module is ALWAYS VISIBLE.
   * If targetProperty is defined, then this module is only visible if the currentProperty is the targetProperty
   * */
  const isVisible = !targetProperty || (targetProperty.title === seriesTitle);

  return (isVisible &&
    <section aria-labelledby='live-module-heading'>
      <Link className={styles.linkStyle} href='/videos/watch/live' prefetch={ false }>
        <div className={ styles.mainContainer }>
          <h2 id='live-module-heading' className='sr-only'>Watch Live TV</h2>
          <div className={ styles.liveWrapper }>
            <StyledIcon
              icon={ LiveLogo }
              classList={styles.liveLogo}
            ></StyledIcon>
            <span>Live</span>
          </div>
          <div className={ styles.currentShowWrapper }>
            {(series?.characterImage?.[0]?.url && series?.navigationCardLogo?.[0]?.url) ?
              <CharacterCard
                className={ styles.characterCard }
                characterImage={ series.characterImage[0].url || '' }
                navigationCardLogo={ series.navigationCardLogo[0].url }
                backgroundClassName={ styles.characterBackground }
              /> :
              <div className={styles.logoWrapper}>
                <StyledIcon
                  icon={ PBSKidsLogo }
                  classList={styles.brandLogo}
                ></StyledIcon>
              </div>
            }

            <div className={styles.textWrapper}>
              <span>Now</span><span className='sr-only'>:&nbsp;</span>
              <div>
                <p>
                  <strong className='font-sans-bold'>{seriesTitle}</strong> {episode?.title || ''}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </section>
  );
};

const LiveModule: FC<Props> = ({ targetProperty, dangerouslyForceSeries }) => {
  return (
    <WhatsOnNowProvider>
      <LiveModuleLink targetProperty={targetProperty} dangerouslyForceSeries={dangerouslyForceSeries} />
    </WhatsOnNowProvider>
  );
};

export default LiveModule;
