import React, { useEffect, useRef, useState } from 'react';
import { PbsKidsVideo, VideoContentCard } from '@/types/pbskids-graph';
import { MediaContext } from '@/types/mediaContext';
import ArrowButtonLeft from '@/components/base/ArrowButton/ArrowButtonLeft';
import ArrowButtonRight from '@/components/base/ArrowButton/ArrowButtonRight';
import ClickAnimation from '@/components/base/ClickAnimationWrapper';
import RowLowerModule from '@/components/modules/RowLowerModule';
import styles from './CarouselModule.module.scss';
import { populateLinkItem, populateVideoItem } from '@/components/modules/MediaList';
import mediaStyles from '@/components/modules/MediaList/MediaItem.module.scss';
import useSwipe from '@/utils/hooks/use-swipe';

interface Props {
  cardStyle?: string,
  context?: MediaContext,
  inMasthead?: boolean,
  items?: PbsKidsVideo[],
  linkItems?: VideoContentCard[],
  listId?: string,
}

enum ContentType {
  videos = 'videos',
  videoContentCards = 'videoContentCards',
}

const setCardClass = (
  cards: HTMLLIElement[] | null[],
  card: HTMLLIElement | null,
  selectedIndex: number,
  lastIndex: number,
  motionDirection: string,
) => {
  if (card && card.dataset.index) {
    const cardClasses = [ mediaStyles.mediaItem, styles.slide ];
    const cardIndex = parseInt(card.dataset.index);
    if (selectedIndex === cardIndex) {
      if (motionDirection === 'left') {
        cardClasses.push(styles.motionLeft);
      }
      if (motionDirection === 'right') {
        cardClasses.push(styles.motionRight);
      }
      cardClasses.push(styles.positionSelected);
    } else if (selectedIndex === cardIndex - 1 || (cardIndex === 0 && selectedIndex === lastIndex)) {
      cardClasses.push(styles.positionNext);
    } else if (selectedIndex === cardIndex + 1 || (cardIndex === lastIndex && selectedIndex === 0)) {
      cardClasses.push(styles.positionPrev);
    }

    if (cards.length > 3) {
      if (selectedIndex === cardIndex + 2 ||
        ((selectedIndex === 0 && cardIndex === lastIndex - 1) || (selectedIndex === 1 && cardIndex === lastIndex))) {
        cardClasses.push(styles.positionPrev, styles.lowerLayer);
      } else if (selectedIndex === cardIndex - 2 ||
        ((selectedIndex === lastIndex && cardIndex === 1) || (selectedIndex === lastIndex - 1 && cardIndex === 0))) {
        cardClasses.push(styles.positionNext, styles.lowerLayer);
      }
    }
    card.className = cardClasses.join(' ');
  }
};

const setCardTabIndex = (card: HTMLLIElement | null, selectedIndex: number) => {
  if (card && card.dataset.index) {
    const cardIndex = parseInt(card.dataset.index);
    const cardLink = card.querySelector('a');
    if (cardLink) {
      if (selectedIndex === cardIndex) {
        cardLink.setAttribute('tabindex', '0');
      } else {
        cardLink.setAttribute('tabindex', '-1');
      }
    }
  }
};

const setCardData = (
  cards: HTMLLIElement[] | null[],
  selectedIndex: number,
  lastCardIndex: number,
  motionDirection: string) => {
  cards.forEach((card) => {
    setCardClass(cards, card, selectedIndex, lastCardIndex, motionDirection);
    setCardTabIndex(card, selectedIndex);
  });
};

const CarouselModule: React.FC<Props> = ({
  cardStyle = 'mezzanine',
  context,
  inMasthead,
  items,
  linkItems,
  listId,
}) => {
  const isCarousel = true;
  const mixedContent = true;
  const prevBtnDisabled = false;
  const nextBtnDisabled = false;
  const [ selectedIndex, setSelectedIndex ] = useState(0);
  const [ motionDirection, setMotionDirection ] = useState('');
  const [ isDesktop, setIsDesktop ] = useState(false);
  const ref = useRef<HTMLLIElement[] | null[]>([]);

  const listContentType = () => {
    if (items && items.length) {
      return ContentType.videos;
    } else if (linkItems && linkItems.length) {
      return ContentType.videoContentCards;
    }
  };

  const getCarouselList = () => {
    switch (listContentType()) {
      case ContentType.videos:
        return items;
      case ContentType.videoContentCards:
        return linkItems;
      default:
        return;
    }
  };

  const carouselList = getCarouselList();

  const lastCardIndex = () => {
    if (items && items.length) {
      return items?.length - 1;
    } else if (linkItems && linkItems.length) {
      return linkItems?.length - 1;
    } else return 0;
  };

  const onPrevButtonClick = () => {
    setMotionDirection('right');
    if (selectedIndex + lastCardIndex() === lastCardIndex()) {
      setSelectedIndex(lastCardIndex());
    } else {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const onNextButtonClick = () => {
    setMotionDirection('left');
    if (lastCardIndex() - selectedIndex === 0) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const swipeHandlers = useSwipe({
    onSwipedLeft: () => onNextButtonClick(),
    onSwipedRight: () => onPrevButtonClick(),
  });

  const setDisplaySize = () => {
    setIsDesktop(window.innerWidth > 567);
  };

  useEffect(() => {
    setDisplaySize();
    window.addEventListener('resize', setDisplaySize);
    setCardData(ref.current, selectedIndex, lastCardIndex(), motionDirection);

    return () => {
      window.removeEventListener('resize', setDisplaySize);
    };
  });

  return (
    <>
      { carouselList?.length && <>
        <section className={`${styles.carousel} ${inMasthead ? styles.inMasthead : ''}`}>
          { !isDesktop &&
            <div className={ styles.mobileCarousel }>
              <RowLowerModule
                items={ items }
                linkItems={ linkItems }
                cardStyle='mezzanine'
                context={ context }
                listId={ listId }
              />
            </div>
          }

          { isDesktop && <>
            <div className={ styles.navButtonContainer }>
              <ClickAnimation
                animationName='pullRight'
                onClick={onPrevButtonClick}
              >
                <ArrowButtonLeft
                  disabled={prevBtnDisabled}
                  edgeStyle={true}
                  moreClasses={styles.navButton}
                />
              </ClickAnimation>
            </div>

            <div className={ styles.carouselWrapper }>
              <div {...swipeHandlers} className={ styles.desktopCarousel}>
                <ul
                  data-ga-observable-module={listId}
                  className={`${styles.container}`}
                >
                  {carouselList?.map((item: PbsKidsVideo | VideoContentCard, index: number ) => (
                    <li
                      className={`${mediaStyles.mediaItem} ${styles.slide}`}
                      data-index={ index }
                      key={ index }
                      ref={(node) => {
                        if (node) {
                          ref.current[index] = node;
                        }
                      }}
                    >
                      <div className={styles.slideInner}>
                        { listContentType() === ContentType.videos &&
                          populateVideoItem(item as PbsKidsVideo, isCarousel, context, cardStyle, inMasthead)
                        }
                        { listContentType() === ContentType.videoContentCards &&
                          populateLinkItem(item as VideoContentCard, isCarousel, mixedContent, cardStyle)
                        }
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className={`${styles.navButtonContainer} ${styles.navRight}`}>
              <ClickAnimation
                animationName='pullLeft'
                onClick={onNextButtonClick}
              >
                <ArrowButtonRight
                  disabled={nextBtnDisabled}
                  edgeStyle={true}
                  moreClasses={`${styles.navButton} ${styles.navRight}`}
                />
              </ClickAnimation>
            </div>
          </>}
        </section>
      </>}
    </>
  );
};

export default CarouselModule;
